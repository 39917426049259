/* eslint-disable jsx-a11y/anchor-is-valid */
import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import i18next, { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Footer } from "../../screens/Footer/Footer";
import TableTag from "../../ui/components/Table/TableTag";
import User from "../../ui/components/User/User";
import { TwentyIcEdit } from "../../ui/components/icons/TwentyIcEdit";
import { TwentyIcEye2 } from "../../ui/components/icons/TwentyIcEye2";
import { TwentyIcUser } from "../../ui/components/icons/TwentyIcUser";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import AuthenticationService from "../../utils/AuthenticationService";
import { decryptPassword, getQueryString } from "../../utils/utils";
import { EditPersonalDataModal } from "../Account/AccountModal/EditPersonalDataModal";
import { QueryParams } from "../Smartplan/SmartPlan";
import {
  TenantAdditionalInfo,
  getTenantAdditionalInfoAsync,
  tenantAdditionalInfoSelector,
} from "../Tenant/TenantAdditionalInfoSlice";
import {
  UserInfo,
  getUserInfoAsync,
  partiallyUpdateUserInfoAsync,
  userInfoSelector,
} from "../UserInfo/UserSlice";
import { AddMemberModal } from "./AddMemberModal";
import "./MyTeam.css";
import { MyTeamFilterBar } from "./MyTeamFilterBar";
import {
  TeamUserInfo,
  addNewUsersViaEmailAsync,
  getFilteredUsersInfoPaginationAsync,
  selectTeamUsersInfoSliceReasonCode,
  selectTeamUsersInfoSliceStatus,
  teamUserInfoSelectors,
  usersInfoStatusAndReasonCodeEmptyState,
} from "./TeamUsersInfoSlice";

export const MyTeam = () => {
  //#region Initial variables

  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [searchKey, setSearchKey] = useState("");
  const [addMemberModalOpened, setAddMemberModalOpened] = useState(false);
  const [param, setParam] = useState(new Map());
  const profile = localStorage.getItem("securityProfile");
  const [type, setType] = useState(profile === "SUPER_ADMIN" ? 1 : 4);
  let userTemp = "";
  //#endregion Initial variables

  //#region Object
  const teamUserInfoStatus = useAppSelector(selectTeamUsersInfoSliceStatus);
  const teamUserInfoReasonCode = useAppSelector(
    selectTeamUsersInfoSliceReasonCode
  );

  const teamUsersInfo: TeamUserInfo[] = useAppSelector(
    teamUserInfoSelectors.selectAll
  );

  const [editPersonalDataModalId, setEditPersonalDataModalId] =
    useState<number>(-1);

  let editPersonalDataModalObj: UserInfo = useAppSelector(
    (state) =>
      userInfoSelector.selectById(state, editPersonalDataModalId) ??
      ({} as UserInfo)
  );

  const [userId, setUserId] = useState<number>(-1);
  const [tenantId, setTenantId] = useState<number>(-1);

  let userInfo: UserInfo = useAppSelector(
    (state) => userInfoSelector.selectById(state, userId) ?? ({} as UserInfo)
  );

  let tenantAdditionalInfo: TenantAdditionalInfo = useAppSelector(
    (state) =>
      tenantAdditionalInfoSelector.selectById(state, tenantId) ??
      ({} as TenantAdditionalInfo)
  );

  const navigate = useNavigate();

  const initialData = {
    firstName: userInfo?.firstname,
    lastName: userInfo?.lastname,
    email: userInfo?.email,
    telephone: userInfo?.telephone,
    profilePhoto: userInfo?.profilePhoto,
    fiscalCode: userInfo?.fiscalCode,
    city: userInfo?.city,
    virtualCooworking: userInfo?.virtualOfficeURL,
    personalOfficeURL: tenantAdditionalInfo?.link ?? "",
  };

  const updatePersonalData = (
    firstName: string,
    lastName: string,
    email: string,
    telephone: string,
    profilePhoto: string,
    fiscalCode: string,
    city: string,
    virtualCooworking: string,
    personalOfficeURL: string
  ) => {
    const updatePersonalData: any = {
      id: userId,
      firstname: firstName,
      lastname: lastName,
      email: email,
      fiscalCode: fiscalCode,
      profilePhoto: profilePhoto,
      city: city,
      telephone: telephone,
      virtualOfficeURL: virtualCooworking,
      personalOfficeURL: personalOfficeURL,
    };

    store
      .dispatch(
        partiallyUpdateUserInfoAsync({
          userInfo: updatePersonalData,
          tenantId: tenantId,
        })
      )
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setEditPersonalDataModalId(-1);
          ToastNotification({
            status: "success",
            description: "",
            toastId: "",
            title: "Dati peronali cambiati con successo",
          });
          store.dispatch(usersInfoStatusAndReasonCodeEmptyState());
          store.dispatch(getFilteredUsersInfoPaginationAsync());
          store.dispatch(getTenantAdditionalInfoAsync(tenantId));
        } else {
          setEditPersonalDataModalId(-1);
          ToastNotification({
            status: "error",
            description: "Internal server error",
            toastId: "loginFailed",
            title: "",
          });
        }
      });
  };

  useEffect(() => {
    if (teamUserInfoStatus === "idle") {
      if (teamUserInfoReasonCode === "invited") {
        ToastNotification({
          status: "success",
          description: "",
          toastId: "emailSended",
          title: i18next.t("toastNotification.emailSuccess"),
        });
        store.dispatch(usersInfoStatusAndReasonCodeEmptyState());
      }
    } else if (teamUserInfoStatus === "failed") {
      ToastNotification({
        status: "error",
        description: "",
        toastId: "emailNotSended",
        title: i18next.t("toastNotification.networkError"),
      });
      store.dispatch(usersInfoStatusAndReasonCodeEmptyState());
    }
  }, [teamUserInfoStatus, teamUserInfoReasonCode]);

  useEffect(() => {
    const newQueryParams = { ...queryParams }; // Clona gli attuali query parameters

    if (param.size > 0) {
      param.forEach((value, key) => {
        // Gestisci i valori nulli o vuoti in modo appropriato
        if (
          value !== null &&
          value !== undefined &&
          (Array.isArray(value) ? value.length > 0 : value !== "")
        ) {
          newQueryParams[key] = value;
        } else {
          delete newQueryParams[key];
        }
      });
      store.dispatch(
        addNewUsersViaEmailAsync({
          queryString: getQueryString(newQueryParams),
        })
      );
    } else {
      // Se la mappa dei parametri è vuota, azzeriamo i parametri esistenti
      Object.keys(newQueryParams).forEach((key) => {
        delete newQueryParams[key];
      });
    }
  }, [param]);

  //#endregion Object

  const columns: ColumnsType<TeamUserInfo> = [
    {
      title: "Utente",
      dataIndex: "fullName",
      key: "id",
      render: (text) => {
        const words = text.split(" ");
        const firstWord = words[0] || "";
        const secondWord = words[1] || "";
        const initials = `${firstWord.charAt(0)}${secondWord.charAt(0)}`;
        userTemp = initials;
        return (
          <div className="myteam-table-user">
            {firstWord !== "" && secondWord !== "" ? (
              <User name={initials} round={true} size="l" />
            ) : (
              <div
                style={{
                  height: "36px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                -
              </div>
            )}
            <div className="myteam-table-user-name">{text}</div>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Area",
      dataIndex: "role",
      render: (text) => {
        if (text === null) {
          return (
            <div
              style={{
                height: "36px",
                alignItems: "center",
                display: "flex",
              }}
            >
              -
            </div>
          );
        }
        return text;
      },
    },
    {
      title: "Luogo",
      dataIndex: "city",
      render: (text) => {
        if (text === null) {
          return (
            <div
              style={{
                height: "36px",
                alignItems: "center",
                display: "flex",
              }}
            >
              -
            </div>
          );
        }
        return text;
      },
    },
    {
      title: "Stato",
      dataIndex: "admin",
      render: (status) => {
        return (
          <>
            {!status ? (
              userTemp !== "" ? (
                <TableTag
                  text={t("myTeam.components.table.status.active")}
                  isAdmin={false}
                />
              ) : (
                <TableTag
                  text={t("myTeam.components.table.status.pending")}
                  isAdmin={false}
                  pending={true}
                />
              )
            ) : (
              <TableTag
                text={t("myTeam.components.table.status.active")}
                isAdmin={true}
              />
            )}
          </>
        );
      },
    },
  ];

  if (profile === "SUPER_ADMIN") {
    columns.push({
      title: "Primo Accesso",
      dataIndex: "isFirstAccess",
      render: (firstAccess) => {
        return (
          <>
            {firstAccess ? (
              <TableTag
                text={t("myTeam.components.table.status.pending")}
                isAdmin={false}
                pending={true}
              />
            ) : (
              <TableTag
                text={t("myTeam.components.table.status.completed")}
                isAdmin={false}
                pending={false}
              />
            )}
          </>
        );
      },
    });
    columns.push({
      title: "Ultimo Accesso",
      dataIndex: "lastAccess",
      render: (text) => {
        if (text === null) {
          return (
            <div
              style={{
                height: "36px",
                alignItems: "center",
                display: "flex",
              }}
            >
              -
            </div>
          );
        }
        return text;
      },
    });

    columns.push({
      title: "",
      dataIndex: "",
      render: (id, value) => {
        return (
          <>
            <Space
              size="middle"
              style={{ marginLeft: "15px", marginRight: "0px" }}
            >
              <a
                onClick={() => {
                  const authenticationService = new AuthenticationService();
                  authenticationService.login(
                    value.email,
                    decryptPassword(
                      process.env.REACT_APP_SUPER_ADMIN_PW,
                      process.env.REACT_APP_SUPER_ADMIN_KEY
                    ),
                    (result: any) => {
                      if (result.isFirstAccess) {
                        navigate("/privacyPolicy");
                      } else {
                        localStorage.setItem("isLoggedIn", "true");
                        navigate("/home");
                      }
                    },
                    (error) => {
                      console.log("LOGIN ERROR: " + error);
                      if (
                        error.response === undefined ||
                        error.response.status === 500
                      ) {
                        ToastNotification({
                          status: "error",
                          description: "",
                          toastId: "loginFailed",
                          title: i18next.t(
                            "loginPage.toastNotification.networkError"
                          ),
                        });
                      } else if (error.response.status === 401) {
                        ToastNotification({
                          status: "error",
                          description: "",
                          toastId: "loginFailed",
                          title: i18next.t(
                            "loginPage.toastNotification.unauthorizedError"
                          ),
                        });
                      } else if (error.response.status === 409) {
                        ToastNotification({
                          status: "error",
                          description: "",
                          toastId: "loginFailed",
                          title: i18next.t(
                            "loginPage.toastNotification.alertExpiredDisabled"
                          ),
                        });
                      } else {
                        ToastNotification({
                          status: "error",
                          description: "",
                          toastId: "loginFailed",
                          title: i18next.t("loginPage.toastNotification.alert"),
                        });
                      }
                    }
                  );
                }}
              >
                <TwentyIcEye2 className={undefined} color="#222729" />
              </a>
            </Space>
            <Space
              size="middle"
              style={{ marginLeft: "15px", marginRight: "0px" }}
            >
              <a
                onClick={() => {
                  setUserId(value.id);
                  store.dispatch(
                    getUserInfoAsync({
                      userInfoId: value.id,
                      tenantId: value.tenantId,
                    })
                  );
                  setTenantId(value.tenantId);
                  store.dispatch(
                    getTenantAdditionalInfoAsync(value.tenantId ?? -1)
                  );
                  setEditPersonalDataModalId(value.id);
                }}
              >
                <TwentyIcEdit className={undefined} color="#222729" />
              </a>
            </Space>
          </>
        );
      },
    });
  }

  useEffect(() => {
    store.dispatch(usersInfoStatusAndReasonCodeEmptyState());
    store.dispatch(getFilteredUsersInfoPaginationAsync());
  }, []);

  function filterBySecurityProfile(type: number, teamUser: TeamUserInfo) {
    switch (type) {
      case 1:
        return teamUser.securityProfile === "PROVIDER";
      case 2:
        return teamUser.securityProfile === "ADMIN";
      case 3:
        return teamUser.securityProfile === "EMPLOYEE";
      case 4:
      case 5:
        return true;
      default:
        return true;
    }
  }

  function filterTeamUsers(teamUsers: TeamUserInfo[], searchKey: string) {
    let sortedTeamUsers = [];
    if (type === 4 || type === 5) {
      sortedTeamUsers = _.sortBy(teamUsers, (x) => x.fullName);
    } else {
      sortedTeamUsers = _.orderBy(
        teamUsers,
        ["isFirstAccess", "lastAccess"],
        ["asc", "desc"]
      );
    }
    return sortedTeamUsers
      .filter((y) => filterBySecurityProfile(type, y))
      .filter((x) => {
        const fullNameMatches = x.fullName
          ?.toString()
          .toLocaleLowerCase()
          .includes(searchKey?.toLocaleLowerCase());
        const emailMatches = x.email
          ?.toString()
          .toLocaleLowerCase()
          .includes(searchKey?.toLocaleLowerCase());
        return fullNameMatches || emailMatches;
      });
  }

  return (
    <>
      <div className="myteam-content">
        <div className="myteam-topbar">
          <div className="myteam-topbar-title-iconplace">
            <TwentyIcUser className="myteam-topbar-title-iconplace-icon" />
          </div>
          <div className="myteam-topbar-title">
            {profile === "SUPER_ADMIN"
              ? t("myTeam.common.users")
              : t("myTeam.common.myTeam")}
          </div>
        </div>
        <MyTeamFilterBar
          searched={(e) => {
            setSearchKey(e);
          }}
          modalStatus={false}
          setModalStatus={(v) => {
            setAddMemberModalOpened(v);
          }}
          setType={setType}
        />
        <div className="myteam-activitytail-results-bar">
          <div className="myteam-activitytail-results-title">
            <div className="myteam-activitytail-results-title-one">
              {t("smartPlan.common.results")}
            </div>
            <div className="myteam-activitytail-results-title-two">
              ({filterTeamUsers(teamUsersInfo, searchKey).length})
            </div>
          </div>
        </div>
        <div className="users-table">
          <Table
            columns={columns}
            dataSource={filterTeamUsers(teamUsersInfo, searchKey)}
            pagination={{
              pageSize: 10,
            }}
          />
        </div>
      </div>
      <Footer />
      {addMemberModalOpened && (
        <div>
          <AddMemberModal
            setParam={(newParam) => setParam(newParam)}
            statusModal={addMemberModalOpened}
            closeModal={(v) => {
              setAddMemberModalOpened(v);
            }}
          ></AddMemberModal>
        </div>
      )}
      {!_.isEmpty(editPersonalDataModalObj) && (
        <EditPersonalDataModal
          onClose={() => {
            setEditPersonalDataModalId(-1);
          }}
          title={"Dati personali"}
          onClick={(
            firstName: string,
            lastName: string,
            email: string,
            telephone: string,
            profilePhoto: string,
            fiscalCode: string,
            city: string,
            virtualCooworking: string,
            personalOfficeURL: string
          ) =>
            updatePersonalData(
              firstName,
              lastName,
              email,
              telephone,
              profilePhoto,
              fiscalCode,
              city,
              virtualCooworking,
              personalOfficeURL
            )
          }
          isActionPerforming={false}
          initialData={initialData}
        />
      )}
    </>
  );
};

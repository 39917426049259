export const TwentyIcArrowLineDiagonal = ({ className, color = "white" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7729 12.2982C12.7105 10.2359 14.9497 5.0504 14.9497 5.0504M14.9497 5.0504C14.9497 5.0504 9.76425 7.28957 7.70185 5.22718M14.9497 5.0504L5.0502 14.9499"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React, { useEffect, useState } from "react";

import "./style.css";
import { Button } from "../Button/Button";
import { TwentyIcAdd } from "../icons/TwentyIcAdd";
import { useNavigate } from "react-router-dom";
import { TwentyIcNotify } from "../icons/TwentyIcNotify";

interface EmptyStateProps {
  icon: boolean;
  description: string;
  title: string;
  setValue: (v: any, openModal: boolean) => any;
  textButton: string;
  isPublicAccountEmpty: boolean;
  isWellnessTableEmpty: boolean;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  icon = true,
  description,
  title,
  setValue,
  textButton,
  isPublicAccountEmpty,
  isWellnessTableEmpty,
}) => {
  const [modalStatuss, setModalStatuss] = useState(false);

  const navigate = useNavigate();

  const userId = localStorage.getItem("customerId");

  useEffect(() => {
    setValue(modalStatuss, isWellnessTableEmpty);
  }, [modalStatuss, isWellnessTableEmpty]);

  return (
    <div className={`empty-state `}>
      <div className="frame-9">
        <div className="frame-10">
          {icon && <TwentyIcNotify className="forty-ic-user" color="black" />}
          <div className="frame-11">
            {title && <div className="non-sono-presenti">{title}</div>}

            {description && (
              <p className="che-aspetti-aggiungi">{description}</p>
            )}
          </div>
        </div>
        <Button
          size="l"
          state="default"
          type="primary"
          text={textButton}
          onClick={() => {
            if (isPublicAccountEmpty) {
              navigate(`/account/${userId}/details/public-account`);
            } else if (isWellnessTableEmpty) {
              setModalStatuss(!modalStatuss);
            }
            setValue(!modalStatuss, isWellnessTableEmpty);
          }}
          disabled={undefined}
          iconRight={undefined}
          icon={<TwentyIcAdd className={undefined} color="white" />}
        />
      </div>
    </div>
  );
};

/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

export const TwentyIcWhatsapp = ({ className, color = "black" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 18H9.99688C8.56138 17.9994 7.15343 17.614 5.92515 16.8853L5.63301 16.712L2.60519 17.506L3.41338 14.555L3.22311 14.2524C2.42231 12.9792 1.99937 11.5075 2 9.9965C2.00173 5.58726 5.59052 2 10.0033 2C12.14 2.00081 14.1486 2.83376 15.659 4.3453C17.1694 5.85685 18.0008 7.86612 18 10.0029C17.9983 14.4125 14.4095 18 10.0002 18Z"
          stroke="#222729"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8853 10.9606C12.6721 10.8834 12.5171 10.8448 12.362 11.0764C12.2069 11.3079 11.7611 11.8289 11.6254 11.9833C11.4897 12.1376 11.354 12.157 11.1214 12.0412C11.0834 12.0222 11.0316 11.9999 10.9677 11.9723C10.6402 11.8311 9.99413 11.5524 9.25077 10.8932C8.55929 10.2799 8.09238 9.52244 7.95669 9.29086C7.82101 9.0593 7.94227 8.93416 8.05871 8.81882C8.12845 8.74976 8.20858 8.65275 8.2887 8.55574C8.32879 8.50721 8.36887 8.45867 8.40766 8.41364C8.5066 8.29875 8.54944 8.21174 8.60777 8.09328C8.618 8.0725 8.6287 8.05076 8.64027 8.02774C8.71782 7.87337 8.679 7.73825 8.62084 7.62249C8.58195 7.54503 8.36083 7.00982 8.16068 6.52535C8.06167 6.28569 7.96779 6.05845 7.90363 5.90519C7.73692 5.50692 7.56786 5.50793 7.43228 5.50873C7.41432 5.50884 7.39695 5.50894 7.38025 5.50811C7.24472 5.5014 7.08948 5.5 6.93442 5.5C6.77937 5.5 6.52734 5.55785 6.31413 5.78941C6.30046 5.80425 6.28519 5.82045 6.2686 5.83806C6.02633 6.0951 5.5 6.65353 5.5 7.71898C5.5 8.85147 6.32483 9.94577 6.44794 10.1091L6.44982 10.1116C6.45745 10.1217 6.47115 10.1413 6.49075 10.1692C6.76968 10.5669 8.24326 12.668 10.4236 13.6041C10.9786 13.8424 11.4118 13.9847 11.7497 14.0913C12.3069 14.2673 12.814 14.2425 13.2149 14.1829C13.6618 14.1165 14.5911 13.6234 14.785 13.0831C14.9788 12.5428 14.9788 12.0798 14.9207 11.9833C14.8738 11.9056 14.7642 11.8529 14.6019 11.7749C14.5626 11.7561 14.5202 11.7357 14.4748 11.7131C14.2422 11.5973 13.0986 11.0378 12.8853 10.9606Z"
          fill="#222729"
        />
      </g>
    </svg>
  );
};

import HttpClient from "../../utils/HttpClient";
import { WalletChargeRequest } from "./WalletSlice";

export default class WalletRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getWallet() {
    const userId = localStorage.getItem("customerId");
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/users/" + userId + "/wallet";
    return this.instance.get(path);
  }
  public getWallets() {
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/wallets";
    return this.instance.get(path);
  }

  public chargeWallet(walletChargeRequest: WalletChargeRequest) {
    const userId = localStorage.getItem("customerId");
    const tenantId = localStorage.getItem("tenantId");
    const path = "/tenants/" + tenantId + "/users/" + userId + "/wallet/charge";
    return this.instance.post(path, walletChargeRequest);
  }

  public getRate(type: string) {
    const path = "/conversionRate/" + type;
    return this.instance.get(path);
  }
}

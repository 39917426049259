import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Space,
  Tooltip,
  Upload,
  UploadFile,
} from "antd";
import Card from "antd/es/card/Card";
import TextArea from "antd/es/input/TextArea";
import Dragger from "antd/es/upload/Dragger";
import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { BaseTab } from "../../../ui/components/BaseTab";
import Form from "../../../ui/components/Form/Form";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowDown } from "../../../ui/components/icons/TwentyIcArrowDown";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcLink } from "../../../ui/components/icons/TwentyIcLink";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { convertBase64, isURL } from "../../../utils/utils";
import {
  PublicAccount,
  getPublicAccountsAsync,
  publicAccountSelector,
} from "../../Account/PublicAccount/PublicAccountSlice";
import {
  addItem,
  removeItem,
  selectGalleryItems,
} from "../../Gallery/GalleryPreviewSlice";
import {
  Gallery,
  getWellnessBodyGalleyAsync,
} from "../../Gallery/GallerySlice";
import {
  UserInfo,
  getUserInfoAsync,
  userInfoSelector,
} from "../../UserInfo/UserSlice";
import { getWellnessTableAsync } from "../../Wellness/WellnessSlice/WellNessTableSlice";
import {
  getWellnessBodyDetailsAsync,
  updateWellnessBodyAsync,
  wellnessBodySelector,
} from "../../Wellness/WellnessSlice/WellnessBodySlice";
import {
  getWellnessNutritionalDetailsAsync,
  updateWellnessNutritionalAsync,
  wellnessNutritionalSelector,
} from "../../Wellness/WellnessSlice/WellnessNutritionalSlice";
import {
  getWellnessPsychoDetailsAsync,
  updateWellnessPsychoAsync,
  wellnessPsychoSelector,
} from "../../Wellness/WellnessSlice/WellnessPsychoSlice";
import "../CreateService/CreateService.css";

export const EditService = () => {
  const navigate = useNavigate();

  const publicAccount: PublicAccount[] = useAppSelector(
    publicAccountSelector.selectAll
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typlology = queryParams.get("typology");

  const selectProviederForService = (providerType: string) => {
    if (providerType === "COACH") {
      return "BODY";
    } else if (providerType === "PSYCHOLOGIST") {
      return "PSYCHO";
    } else if (providerType === "NUTRITIONIST") {
      return "NUTRITIONAL";
    } else {
      return null;
    }
  };

  const [getImageService, setGetImageService] = useState<UploadFile<any>[]>([]);

  const [getGalleryService, setGetGalleryService] = useState<UploadFile<any>[]>(
    []
  );

  const providerType =
    typlology ?? selectProviederForService(publicAccount[0]?.providerType);

  const [userId, setUserId] = useState<number>(-1);

  const editServiceRoute = useMatch("myService/:id/edit");

  useEffect(() => {
    if (editServiceRoute !== null && editServiceRoute.params.id) {
      setUserId(parseInt(editServiceRoute.params.id));

      switch (providerType) {
        case "BODY":
          store.dispatch(
            getWellnessBodyDetailsAsync(parseInt(editServiceRoute.params.id))
          );

          previewGallery.length === 0 &&
            store.dispatch(
              getWellnessBodyGalleyAsync({
                wellnessId: parseInt(editServiceRoute.params.id),
              })
            );
          break;

        case "NUTRITIONAL":
          store.dispatch(
            getWellnessNutritionalDetailsAsync(
              parseInt(editServiceRoute.params.id)
            )
          );

          break;

        default:
          store.dispatch(
            getWellnessPsychoDetailsAsync(parseInt(editServiceRoute.params.id))
          );

          break;
      }
    }
  }, [editServiceRoute, providerType]);

  let wellness = useAppSelector((state) => {
    switch (providerType) {
      case "BODY":
        return wellnessBodySelector.selectById(state, userId);

      case "NUTRITIONAL":
        return wellnessNutritionalSelector.selectById(state, userId);

      case "PSYCHO":
        return wellnessPsychoSelector.selectById(state, userId);

      default:
        return null;
    }
  });

  const [isLinkValid, setIsLinkValid] = useState(true);
  const [deletedPhoto, setDeletedPhoto] = useState<any[]>([]);
  const [titleString, setTitleString] = useState("");
  const [description, setDescription] = useState("");
  const [typologyDropdown, setTypologyDropdown] = useState("");
  const [draftDropdown, setDraftDropdown] = useState("");
  const [positionDropdown, setPositionDropdown] = useState("");
  const [numbersessions, setNumberSessions] = useState("");
  const [duration, setDuration] = useState("");
  const [presenceOrRemotely, setPresenceOrRemotely] = useState<string>("");
  const [price, setPrice] = useState<number>();
  let [isIndividual, setIsIndividual] = useState<boolean>();
  const [seats, setSeats] = useState<number | undefined>(2);
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [cap, setCap] = useState("");
  const [link, setLink] = useState("");
  const [photo, setPhoto] = useState<any | undefined>();
  const [gallery, setGallery] = useState<String[]>([]);
  let positionString: string = wellness?.location ?? "";
  let positionStringTransformed =
    positionString?.charAt(0).toUpperCase() +
      positionString?.slice(1).toLowerCase() ?? "";
  const tenantId = localStorage.getItem("tenantId");
  const userInfoId = localStorage.getItem("customerId");
  let previewGallery: Gallery[] = useAppSelector(selectGalleryItems);
  const [galleryImage, setGalleryImage] = useState<any>([]);

  const userInfo: UserInfo = useAppSelector(
    (state) =>
      userInfoSelector.selectById(state, userInfoId ?? "") ?? ({} as UserInfo)
  );

  useEffect(() => {
    store.dispatch(getPublicAccountsAsync()).then(() => {
      if (providerType === "NUTRITIONAL") {
        setIsIndividual(true);
        setSeats(1);
      }
    });

    store.dispatch(
      getUserInfoAsync({
        userInfoId: parseInt(userInfoId ?? ""),

        tenantId: tenantId,
      })
    );
  }, []);

  useEffect(() => {
    if (
      !!wellness &&
      !_.isEmpty(wellness) &&
      wellness?.image !== null &&
      wellness?.image !== undefined &&
      wellness?.image !== ""
    ) {
      const uploadFile: UploadFile = {
        uid: wellness?.id.toString(),
        name: "Immagine caricata",
        status: "done",
        url: wellness?.image,
      };
      if (_.isEmpty(getImageService)) {
        getImageService.push(uploadFile);
      }
    }
  }, [wellness]);

  useEffect(() => {
    if (!_.isEmpty(previewGallery) && getGalleryService.length === 0) {
      previewGallery.map((e: any) => {
        const uploadFile: UploadFile = {
          uid: e.id,
          name: "Immagine caricata",
          status: "done",
          url: e.image,
        };

        getGalleryService.push(uploadFile);
      });
    }
  }, [previewGallery]);

  useEffect(() => {
    if (wellness) {
      setTitleString(wellness?.name);
      setDescription(wellness?.description);
      setTypologyDropdown(wellness?.typology);
      setNumberSessions(
        numMeetingInverse(wellness?.numberMeeting ?? 0)?.toString() ?? ""
      );
      setSeats(wellness?.sessionsAvailable);
      setIsIndividual(wellness?.isIndividual);
      setPrice(wellness?.price);
      setPresenceOrRemotely(
        remotelyOrPresenceInverse(wellness?.remotelyOrPresenceEnum ?? "") ?? ""
      );
      setCity(wellness?.city ?? "");
      setProvince(wellness?.province ?? "");
      setPositionDropdown(positionStringTransformed);
      setAddress(wellness?.address ?? "");
      setCap(wellness?.zip ?? "");
      setLink(
        isURL(wellness?.link) ? wellness?.link : "https://" + wellness?.link
      );
      setPhoto(wellness?.image ?? "");
      setGallery(galleryImage && galleryImage);
      setDraftDropdown(wellness?.published ? "Pubblica" : "Bozza");
      setDuration(wellness?.durationMeeting);
    }
  }, [wellness]);

  const updateWellnessOnClick = async () => {
    try {
      const numMeeting = (numbersessions: string) => {
        switch (numbersessions) {
          case "Seduta individuale":
            return 1;

          case "5 sedute":
            return 5;

          case "8 sedute":
            return 8;

          default:
            return "Seduta individuale";
        }
      };

      const getCurrentWellness = (typology: string) => {
        const wellnessTypes: Record<
          string,
          { update: Function; type: string }
        > = {
          BODY: { update: updateWellnessBodyAsync, type: "wellnessBody" },

          NUTRITIONAL: {
            update: updateWellnessNutritionalAsync,
            type: "wellnessNutritional",
          },

          PSYCHO: {
            update: updateWellnessPsychoAsync,
            type: "wellnessPsycho",
          },
        };

        return wellnessTypes[typology] || null;
      };

      const getWellnessData = () => {
        const baseData = {
          name: titleString ?? "",
          description: description,
          typology: typologyDropdown,
          numberMeeting: numMeeting(numbersessions ?? ""),
          durationMeeting: duration ?? "",
          price: price,
          isIndividual: isIndividual,
          link: link,
          sessionsAvailable: isIndividual === false ? 1 : seats,
          remotelyOrPresenceEnum: "REMOTE",
          image: photo,
          approved: false,
          active: true,
          published: draftDropdown === "Pubblica" ? true : false,
        };

        if (presenceOrRemotely !== "A distanza") {
          const wellnessBodyDatas = {
            ...baseData,
            city: city,
            province: province ?? "",
            address: address,
            zip: cap,
            location: positionDropdown ? positionDropdown?.toUpperCase() : null,
          };

          return wellnessBodyDatas;
        }

        if (providerType === "BODY") {
          const wellnessBodyData = {
            ...baseData,
            deletedPhotoGallery:
              deletedPhoto && deletedPhoto.length > 0 ? deletedPhoto : [],
            gallery: gallery ?? [],
          };

          return wellnessBodyData;
        }

        return baseData;
      };

      const wellnessData = getWellnessData();

      const wellnessConfig = getCurrentWellness(providerType ?? "");

      if (wellnessConfig) {
        const result = await store.dispatch(
          wellnessConfig.update({ [wellnessConfig.type]: userId, wellnessData })
        );

        if (result.meta.requestStatus === "fulfilled") {
          navigate("/myService");

          store.dispatch(getWellnessTableAsync());

          ToastNotification({
            status: "success",

            description: "",

            toastId: "Service created",

            title: t("toastNotification.successfully"),
          });
        } else {
          ToastNotification({
            status: "error",

            description: "",

            toastId: "Service not created",

            title: t("toastNotification.networkError"),
          });
        }
      }
    } catch (error) {
      console.error("Error creating wellness service:", error);

      ToastNotification({
        status: "error",

        description: "",

        toastId: "Service not created",

        title: t("toastNotification.networkError"),
      });
    }
  };

  const functionTitle = (typology: string | undefined) => {
    switch (typology) {
      case "BODY":
        return "Servizio - Attività psico-fisiche";

      case "NUTRITIONAL":
        return "Servizio - Sedute nutrizionistiche";

      case "PSYCHO":
        return "Servizio - Sedute psicologiche";

      default:
        return "";
    }
  };

  const functionTypology = (typology: string) => {
    let typologyItems: MenuProps["items"] = [];

    switch (typology) {
      case "BODY":
        typologyItems = [
          {
            label: t("myService.dropdownCreate.typologyBody1"),

            key: t("myService.dropdownCreate.typologyBody1"),
          },

          {
            label: t("myService.dropdownCreate.typologyBody2"),

            key: t("myService.dropdownCreate.typologyBody2"),
          },

          {
            label: t("myService.dropdownCreate.typologyBody3"),

            key: t("myService.dropdownCreate.typologyBody3"),
          },

          {
            label: t("myService.dropdownCreate.typologyBody5"),
            key: t("myService.dropdownCreate.typologyBody5"),
          },
          {
            label: t("myService.dropdownCreate.typologyBody6"),
            key: t("myService.dropdownCreate.typologyBody6"),
          },
        ];

        break;

      case "NUTRITIONAL":
        typologyItems = publicAccount[0].typologies.map((el) => ({
          label: el.name,

          key: el.name,
        }));

        break;

      case "PSYCHO":
        typologyItems = publicAccount[0].typologies.map((el) => ({
          label: el.name,

          key: el.name,
        }));

        break;

      default:
        break;
    }

    return typologyItems;
  };

  const itemServiceDuration: MenuProps["items"] = [
    {
      label: t("myService.dropdownDurations.one"),

      key: t("myService.dropdownDurations.one"),
    },

    {
      label: t("myService.dropdownDurations.two"),

      key: t("myService.dropdownDurations.two"),
    },

    {
      label: t("myService.dropdownDurations.three"),

      key: t("myService.dropdownDurations.three"),
    },

    {
      label: t("myService.dropdownDurations.four"),

      key: t("myService.dropdownDurations.four"),
    },

    {
      label: t("myService.dropdownDurations.five"),

      key: t("myService.dropdownDurations.five"),
    },
  ];

  const numMeetingInverse = (numbersessions: number) => {
    switch (numbersessions) {
      case 1:
        return "Seduta individuale";

      case 5:
        return "5 sedute";

      case 8:
        return "8 sedute";

      default:
        return "Seduta individuale";
    }
  };

  const remotelyOrPresenceInverse = (presenceOrRemotely: string) => {
    switch (presenceOrRemotely) {
      case "PRESENCE":
        return "In presenza";

      case "REMOTE":
        return "A distanza";

      case "REMOTEANDPRESENCE":
        return "In presenza e distanza";

      default:
        return null;
    }
  };

  const itemPrice: MenuProps["items"] = [
    {
      label: t("myService.dropdownSessions.oneSession"),

      key: t("myService.dropdownSessions.oneSession"),
    },

    {
      label: t("myService.dropdownSessions.fiveSession"),

      key: t("myService.dropdownSessions.fiveSession"),
    },

    {
      label: t("myService.dropdownSessions.eightSession"),

      key: t("myService.dropdownSessions.eightSession"),
    },
  ];

  const draftItems: MenuProps["items"] = [
    {
      label: t("myService.dropdownDraft.public"),

      key: t("myService.dropdownDraft.public"),
    },

    {
      label: t("myService.dropdownDraft.draft"),

      key: t("myService.dropdownDraft.draft"),
    },
  ];

  const onTypologyClick: MenuProps["onClick"] = ({ key }) => {
    setTypologyDropdown(key);
  };

  const onSessionsClick: MenuProps["onClick"] = ({ key }) => {
    setNumberSessions(key);
  };

  const onDurationClick: MenuProps["onClick"] = ({ key }) => {
    setDuration(key);
  };

  const onDraftClick: MenuProps["onClick"] = ({ key }) => {
    setDraftDropdown(key);
  };

  const handleIsIndividual = (newIsIndividual: boolean, newSeats: number) => {
    if (newIsIndividual !== isIndividual) {
      setIsIndividual(newIsIndividual);

      setSeats(newSeats);
    }
  };

  const handleIsIndividualChange = (e: any) => {
    const value = parseInt(e.target.value);

    setSeats(isIndividual === false ? 1 : value);
  };

  const handlePriceChange = (e: any) => {
    if (parseInt(e.target.value) >= 0 || e.target.value === "") {
      setPrice(e.target.value);
    }
  };

  const handleSave = (
    title: string,
    description: string,
    seats: number,
    draftDropdown: string,
    numbersessions: string,
    price: number,
    presenceOrRemotely: string,
    timeSessions: string
  ) => {
    const conditions = [
      title !== "",
      description !== "",
      seats !== null,
      draftDropdown !== "",
      numbersessions !== "",
      price.toString() !== "",
      price.toString() !== "0",
      presenceOrRemotely !== "",
      timeSessions !== "",
      isLinkValid,
      providerType === "BODY" ? !!photo : true,
    ];

    return !conditions.every(Boolean);
  };

  const uploadFilePhotoService = (file: File) => {
    convertBase64(file).then((response) => {
      setPhoto(response);
    });
  };

  const uploadFileGalleryService = (file: any) => {
    convertBase64(file).then((response) => {
      setGallery((prevGallery) => [...prevGallery, response]);
      let object: Gallery = {
        id: file.uid,
        image: response,
      };
      store.dispatch(addItem(object));
    });
  };

  return (
    <>
      <div className="create-service-filterbar">
        {!wellness?.approved && (
          <div className="draft-dropdown">
            <Dropdown
              menu={{
                items: draftItems,

                onClick: onDraftClick,
              }}
            >
              <div className="button-dropdown-draft">
                <Button>
                  <div className="space-draft">
                    <Space>
                      <div
                        className={
                          draftDropdown
                            ? "myService-filtermodal-text"
                            : "myService-filtermodal-text-select"
                        }
                      >
                        {draftDropdown ? draftDropdown : "Salva come"}
                      </div>

                      <TwentyIcArrowDown
                        color="#36B39E"
                        className={undefined}
                      />
                    </Space>
                  </div>
                </Button>
              </div>
            </Dropdown>
          </div>
        )}
        {draftDropdown === "Pubblica" &&
        handleSave(
          titleString,
          description,
          seats ?? 0,
          draftDropdown,
          numbersessions ?? "",
          price ?? 0,
          presenceOrRemotely ?? "",
          duration ?? ""
        ) ? (
          <div className="save-button">
            <Tooltip
              placement="left"
              title={
                "Prima di salvare il servizio, è necessaria la compilazione di tutti i campi."
              }
            >
              <Button
                disabled={
                  draftDropdown === "Pubblica" &&
                  handleSave(
                    titleString,
                    description,
                    seats ?? 0,
                    draftDropdown,
                    numbersessions ?? "",
                    price ?? 0,
                    presenceOrRemotely ?? "",
                    duration ?? ""
                  )
                }
                onClick={() => {
                  updateWellnessOnClick();
                }}
              >
                <div className="space-save">
                  <Space>
                    <div className="smartplan-filtermodal-text-white">
                      {t("myService.components.filterBar.save")}
                    </div>
                  </Space>
                </div>
              </Button>
            </Tooltip>
          </div>
        ) : (
          <div className="save-button">
            <Button
              disabled={
                draftDropdown === "Pubblica" &&
                handleSave(
                  titleString,
                  description,
                  seats ?? 0,
                  draftDropdown,
                  numbersessions ?? "",
                  price ?? 0,
                  presenceOrRemotely ?? "",
                  duration ?? ""
                )
              }
              onClick={() => {
                updateWellnessOnClick();
              }}
            >
              <div className="space-save">
                <Space>
                  <div className="smartplan-filtermodal-text-white">
                    {t("myService.components.filterBar.save")}
                  </div>
                </Space>
              </div>
            </Button>
          </div>
        )}
      </div>

      <div className="header-menu">
        <HeaderMenu>
          <TopBar
            title={titleString}
            breadcrumbText={"Servizi > " + titleString}
            icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
            onClick={() => navigate("/myService")}
            hasEdit={false}
            hasDate={false}
          />

          <div className="line-create-service " />
        </HeaderMenu>
      </div>

      <div className="page-content">
        <div className="title-card-title">
          {functionTitle(providerType ?? "")}

          <div className="card-title">
            <Card className="card-font">
              <div className="title-text">
                Titolo e descrizione del servizio
              </div>

              <div className="title-text-up">Titolo</div>

              <div className="input-title">
                <Form>
                  <div className="input-title-edit">
                    <Input
                      className="input-text-style"
                      id="username-field"
                      value={titleString}
                      size="large"
                      placeholder="Inserisci titolo"
                      onChange={(e) => setTitleString(e.target.value)}
                    />
                  </div>
                </Form>
              </div>

              <div className="title-text-up">Descrizione</div>

              <div className="input-desc">
                <TextArea
                  className="input-text-style"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Descrizione dell'evento"
                />
              </div>
            </Card>
          </div>
        </div>

        <div className="title-card-typology">
          Informazione base
          <div className="card-typology">
            <Card className="card-font">
              <div className="title-text">Tipologia</div>
              {providerType === "BODY" && (
                <>
                  <div className="title-text-up">Tipologia di servizio</div>
                  <div className="dropdown-typology">
                    <Dropdown
                      menu={{
                        items: functionTypology(providerType ?? ""),
                        onClick: onTypologyClick,
                      }}
                    >
                      <div className="button-dropdown-typology">
                        <Button>
                          <div className="space-dropdown-typology">
                            <Space>
                              <div
                                className={
                                  typologyDropdown
                                    ? "myService-filtermodal-text"
                                    : "myService-filtermodal-text-select"
                                }
                              >
                                {typologyDropdown
                                  ? typologyDropdown
                                  : t("smartPlan.filterModal.select")}
                              </div>
                              <TwentyIcArrowDown
                                color="#36B39E"
                                className={undefined}
                              />
                            </Space>
                          </div>
                        </Button>
                      </div>
                    </Dropdown>
                  </div>
                </>
              )}
              <div className="price-duration-text-up">Prezzo e durata</div>

              <div className="line-price">
                <div className="time-picker">
                  <div className="session-text-up">
                    Durata della seduta
                    <Dropdown
                      menu={{
                        items: itemServiceDuration,

                        onClick: onDurationClick,
                      }}
                    >
                      <div className="button-dropdown-seats">
                        <Button>
                          <div className="space-dropdown-seats">
                            <Space>
                              <div
                                className={
                                  duration
                                    ? "myService-filtermodal-text"
                                    : "myService-filtermodal-text-select"
                                }
                              >
                                {duration
                                  ? duration
                                  : t("smartPlan.filterModal.select")}
                              </div>

                              <TwentyIcArrowDown
                                color="#36B39E"
                                className={undefined}
                              />
                            </Space>
                          </div>
                        </Button>
                      </div>
                    </Dropdown>
                  </div>
                </div>

                <div className="input-price">
                  <div className="price-text-up">
                    Prezzo desiderato del servizio
                    <Input
                      className="input-text-style"
                      addonAfter={"€"}
                      size="large"
                      type="number"
                      placeholder="Inserisci prezzo"
                      value={price}
                      onChange={(e) => handlePriceChange(e)}
                    />
                  </div>
                </div>
              </div>

              {providerType !== "NUTRITIONAL" && (
                <>
                  <div className="person-text-up">Posti e modalità</div>
                  <div className="line-sessions">
                    <div className="dropdown-typology">
                      <div className="session-text-up">
                        Sessione
                        <div className="myService-tabs">
                          <BaseTab
                            select={!isIndividual}
                            text={"Singola"}
                            onClick={() => {
                              if (!!isIndividual)
                                handleIsIndividual(!isIndividual, 1);
                            }}
                          ></BaseTab>

                          <BaseTab
                            select={isIndividual}
                            text={"Gruppi"}
                            onClick={() => {
                              if (!isIndividual)
                                handleIsIndividual(!isIndividual, 2);
                            }}
                          ></BaseTab>
                        </div>
                      </div>
                    </div>

                    <div className="input-persons">
                      Posti
                      <div className="input-person">
                        <Input
                          className="input-text-style"
                          addonAfter={"Pers."}
                          type="number"
                          size="large"
                          placeholder="Inserisci n. posti"
                          value={seats}
                          onChange={(e) => handleIsIndividualChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>

        <div className="title-card-hourly-availability">
          Disponibilità oraria
          <div className="card-hourly-availability">
            <Card className="card-font">
              <div className="title-text-union">
                <div className="title-text-2">
                  1. Imposta la tua disponibilità oraria
                </div>
                <div className="title-text-p1">
                  Clicca il link e crea il tuo calendario personalizzato
                  su&nbsp;
                  <a
                    className="title-text-p2"
                    href="https://calendly.com/signup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    calendly.com
                  </a>
                </div>
              </div>
              <div className="title-text-union">
                <div className="title-text-2">2. Inserisci il link</div>
                <div className="title-text-p1">
                  Inserendo il link, i clienti visualizzeranno le tue
                  disponibilità e potranno scegliere la più adatta alle loro
                  esigenze.
                </div>
              </div>
              <div className="input-citys">
                <Form>
                  <Input
                    style={!isLinkValid ? { borderColor: "red" } : undefined}
                    prefix={<TwentyIcLink className={undefined} color="gray" />}
                    id="field"
                    value={link}
                    size="large"
                    placeholder="Link"
                    onChange={(e) => {
                      setLink(e.target.value);
                      setIsLinkValid(isURL(e.target.value));
                    }}
                  />
                </Form>
                {!isLinkValid && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "500",
                      position: "absolute",
                    }}
                  >
                    Inserire un link valido
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>

        {providerType === "BODY" && (
          <div className="title-card-photo">
            Caricamento foto
            <div className="card-photo">
              <Card className="card-font">
                <div className="title-text">Copertina</div>

                <div className="service-photo">
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size="large"
                  >
                    <div className="service-photo">
                      <Upload
                        defaultFileList={getImageService}
                        customRequest={({ file, onSuccess }) => {
                          if (onSuccess !== undefined) {
                            uploadFilePhotoService(file as File);

                            onSuccess("ok");
                          }
                        }}
                        onChange={(e: any) => {
                          if (e.fileList.length === 0) {
                            localStorage.removeItem(
                              "imageMyServiceBackgroundPreview"
                            );
                            setPhoto(null);
                          }
                        }}
                        action={undefined}
                        listType="picture"
                        maxCount={1}
                      >
                        <div className="button-upload">
                          <Button>
                            <div className="text-update">Upload</div>
                          </Button>
                        </div>
                      </Upload>
                    </div>
                  </Space>
                </div>

                <div className="text-photo-m">
                  Misura consigliata:
                  <div className="text-photo-desc"> 1200 x 250px </div>
                </div>

                <div className="text-photo-p">
                  Peso massimo per file:
                  <div className="text-photo-desc"> 1 mb</div>
                </div>

                <>
                  <div className="gallery-text">Galleria</div>

                  <div className="font-gallery">
                    <Dragger
                      defaultFileList={getGalleryService}
                      customRequest={({ file, onSuccess }) => {
                        if (onSuccess !== undefined) {
                          uploadFileGalleryService(file as File);

                          onSuccess("ok");
                        }
                      }}
                      onRemove={(e) => {
                        setDeletedPhoto((photo) => [...photo, parseInt(e.uid)]);
                        store.dispatch(removeItem(e.uid));
                      }}
                      action={undefined}
                      listType="picture"
                      maxCount={6}
                    >
                      <div className="text-t">
                        Trascina qui i tuoi file &nbsp;
                        <div className="text-o">
                          - oppure - &nbsp;
                          <div className="text-c"> Carica manualmente</div>
                        </div>
                      </div>
                    </Dragger>
                  </div>

                  <div className="text-photo-m">
                    Misura consigliata:
                    <div className="text-photo-desc"> 800 x 450px </div>
                  </div>

                  <div className="text-photo-p">
                    Peso massimo per file:
                    <div className="text-photo-desc"> 1 mb</div>
                  </div>
                </>
              </Card>
            </div>
          </div>
        )}
      </div>

      <div className="background-footer">
        <Footer />
      </div>
    </>
  );
};

import PropTypes from "prop-types";

import "./Button.css";

export const Button = ({
  size,

  state,

  type,

  onClick,

  disabled,

  icon,

  iconRight,

  text,

  textColor,

  skip = false,

  isActionPerforming,
}) => {
  const getSizeClassName = () => {
    switch (size) {
      case "S":
        return "button-small";

      case "M":
        return "button-medium";

      case "L":
        return "button-large";

      case "XL":
        return "button-extra-large";

      default:
        return "";
    }
  };

  const getStateClassName = () => {
    switch (state) {
      case "default":
        return "button-default";

      case "hover":
        return "button-hover";

      case "pressed":
        return "button-pressed";

      case "disabled":
        return "button-disabled";

      default:
        return "";
    }
  };

  const handleClick = (e) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  const getTypeClassName = () => {
    switch (type) {
      case "primary":
        return "button-primary";

      case "secondary":
        return "button-secondary";

      case "tertiary":
        return "button-tertiary";

      case "line":
        return "button-line";

      case "ghost":
        return "button-ghost";

      default:
        return "";
    }
  };

  const buttonClassName = `button ${getSizeClassName()} ${getStateClassName()} ${
    skip ? "button-skip-secondary" : getTypeClassName()
  }`;

  if (skip) textColor = "white";

  return (
    <button
      className={buttonClassName}
      onClick={(e) => handleClick(e)}
      disabled={disabled || isActionPerforming}
    >
      {icon && <span className="button-icon">{icon}</span>}

      {text && (
        <span className="button-text" style={{ color: textColor }}>
          {text}
        </span>
      )}

      {iconRight && <span className="button-icon">{iconRight}</span>}

      {isActionPerforming && <span class="loader"></span>}
    </button>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(["l", "m", "XL", "s"]),

  style: PropTypes.oneOf([
    "icon-ghost-line",

    "line",

    "icon-ghost-white",

    "secondary",

    "primary",

    "icon-ghost",

    "terthiary",

    "icon-line",

    "ghost",
  ]),

  text: PropTypes.string,

  icon: PropTypes.element,

  textColor: PropTypes.string,
};

// Imposta un valore predefinito per isActionPerforming

Button.defaultProps = {
  isActionPerforming: false,
};

import { useEffect, useState } from "react";
import { TwentyIcCreditCoins } from "../../../ui/components/TwentyIcCreditCoins";
import "../Admin/CreditManagementAdmin.css";
import { BaseTab } from "../../../ui/components/BaseTab";
import Card from "antd/es/card/Card";
import { TwentyIcCalendarSeet } from "../../../ui/components/icons/TwentyIcCalendarSeet";
import { TwentyIcCreditsCoinsAdd } from "../../../ui/components/icons/TwentyIcCreditsCoinsAdd/TwentyIcCreditsCoinsAdd";
import { TwentyIcStatBox } from "../../../ui/components/icons/TwentyIcStatBox";
import Table, { ColumnsType } from "antd/es/table";
import { store } from "../../../app/store";
import {
  CreditHistory,
  Transaction,
  creditHistorySelector,
  getMyEmployeeRechargeAsync,
} from "../CreditHistorySlice";
import { useAppSelector } from "../../../app/hooks";
import User from "../../../ui/components/User/User";
import { addDotToNumber, formatDate } from "../../../utils/utils";
import { CreditRechargeEmployeeModal } from "./CreditRechargeEmployeeModal";
import {
  Wallet,
  WalletChargeRequest,
  chargeWalletAsync,
  getWalletAsync,
  getWalletsAsync,
  walletSelector,
} from "../../Wallet/WalletSlice";
import { Progress, Button, Space } from "antd";
import { TwentyIcArrowLineDiagonal } from "../../../ui/components/icons/TwentyIcArrowLineDiagonal/TwentyIcArrowLineDiagonal";
import { TwentyIcArrowsLineSortVertical } from "../../../ui/components/icons/TwentyIcArrowsLineSortVertical";
import { TwentyIcStatLine } from "../../../ui/components/icons/TwentyIcStatLine";
import { it } from "date-fns/locale";
import "../Admin/CreditManagementAdmin.css";
import ChargeCreditsModal from "./ChargeCreditsModal";
import {
  TransactionSlice,
  getTransactionsAsync,
  transactionSelector,
} from "../TransactionSlice/TransactionSlice";
import { getTransactionDetailsAsync } from "../TransactionSlice/TransactionDetailsSlice";
import { format, parseISO } from "date-fns";
import _ from "lodash";
import { TransactionRechargeModal } from "../TransactionDetailsModal/TransactionRechargeModal";
import { TransactionDetailsModal } from "../TransactionDetailsModal/TransactionDetailsModal";

export const CreditManagementAdmin = () => {
  const [elementSelected, setElementSelected] = useState(0);
  const [sendCreditModalOpened, setSendCreditModalOpened] = useState(false);
  const userId = localStorage.getItem("customerId");
  const creditHistory: CreditHistory[] = useAppSelector(
    creditHistorySelector.selectAll
  ).sort((a, b) => {
    return (
      new Date(b.transaction.date).getTime() -
      new Date(a.transaction.date).getTime()
    );
  });

  console.log(creditHistory);

  let userTemp = "";

  const [wallet, setWallet] = useState<Wallet>();

  const wallets: Wallet[] = useAppSelector(walletSelector.selectAll);

  useEffect(() => {
    store.dispatch(getMyEmployeeRechargeAsync(Number.parseInt(userId ?? "0")));
    store.dispatch(getWalletsAsync());
    store.dispatch(getWalletAsync());
  }, []);

  useEffect(() => {
    setWallet(wallets.filter((e) => e.userId === parseInt(userId ?? "0"))[0]);
  }, [wallets]);
  const [chargeCreditsModal, setChargeCreditsModal] = useState(false);
  const [detailsId, setDetailsId] = useState<number>(-1);
  const [openModalDetails, setOpenModalDetails] = useState<boolean>(false);
  const [openModalRechargeDetails, setOpenModalRechargeDetails] =
    useState<boolean>(false);

  const transactions: TransactionSlice[] = useAppSelector(
    transactionSelector.selectAll
  );
  const [transaction, setTransaction] = useState<any>();

  useEffect(() => {
    if (!_.isEmpty(transactions)) {
      const foundTransaction = transactions.find((el) => el.id === detailsId);
      if (foundTransaction !== undefined) {
        setTransaction(foundTransaction);
        store.dispatch(
          getTransactionDetailsAsync({
            transactionalSummaryId: detailsId,
          })
        );
      }
    }
  }, [detailsId]);

  const handleModal = (type: string) => {
    if (type === "SENT") {
      setOpenModalDetails(true);
    } else if (type === "RECHARGED") {
      setOpenModalRechargeDetails(true);
    }
  };

  const columnsHistory: ColumnsType<any> = [
    {
      title: "Utente",
      key: "id",
      render: (element: CreditHistory) => {
        const words = [
          element.user.firstname ?? "",
          element.user.lastname ?? "",
        ];
        const firstWord = words[0] || "";
        const secondWord = words[1] || "";
        const initials = `${firstWord.charAt(0)}${secondWord.charAt(0)}`;
        userTemp = initials;
        return (
          <div className="myteam-table-user">
            {firstWord !== "" && secondWord !== "" ? (
              <User name={initials} round={true} size="l" />
            ) : (
              <div
                style={{
                  height: "36px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                -
              </div>
            )}
            <div className="myteam-table-user-name">
              {words[0] + " " + words[1]}
            </div>
          </div>
        );
      },
    },
    {
      title: "Ultimo accredito",
      dataIndex: "transaction",
      key: "",
      render: (element: Transaction) => {
        return (
          <div className="credit-table-last-recharge-container">
            <div className="input-text-style-table-first">
              {element?.creditAmount !== null
                ? element?.creditAmount + " " + "crediti"
                : ""}
            </div>
            <div className="input-text-style-table-last">
              {element?.date ? formatDate(element?.date) : ""}
            </div>
          </div>
        );
      },
    },
    {
      title: "C. Tot. caricati",
      dataIndex: "totalRecharge",
      key: "",
      render: (text) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <TwentyIcCreditCoins
              className={undefined}
              color={"#7a7d7f"}
              colorC={"#7a7d7f"}
            />
            <div className="input-text-style-table-first">
              {text ? text : ""}
            </div>
          </div>
        );
      },
    },
    {
      title: "Saldo utilizzato",
      key: "",
      render: (element: CreditHistory) => {
        return (
          <Progress
            style={{ width: "80%" }}
            percent={
              (element.totalRecharge ?? 0) - (element.creditsAvabile ?? 0)
            }
            size="default"
            strokeColor="#35b39e"
            trailColor="rgba(189, 190, 191, 1)"
            showInfo={false}
          />
        );
      },
    },
    {
      title: "Crdt. disponibili",
      dataIndex: "creditsAvabile",
      key: "",
      render: (text) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <TwentyIcCreditCoins
              className={undefined}
              color={"#35b39e"}
              colorC={"#35b39e"}
            />
            <div
              className="input-text-style-table-first"
              style={{ color: "#35b39e" }}
            >
              {" "}
              {text}
            </div>
          </div>
        );
      },
    },
  ];

  const columnsActivity: ColumnsType<TransactionSlice> = [
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (type, record) => (
        <>
          <div className="table-activity-type">
            {convertTypeIcon(record?.type)}
            <div className="table-activity-type-text">
              {convertType(record?.type)}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Crediti Inviati",
      dataIndex: "totalCreditSent",
      key: "totalCreditSent",
      render: (totalCreditSent, record) => (
        <>
          <div className="table-activity-credit">
            <TwentyIcCreditsCoinsAdd
              className={undefined}
              color="gray"
              colorC="gray"
            />
            <div className="table-activity-credit-text">
              {creditManagemenTable(record?.type) + record?.totalCreditSent}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <>
          <div className="table-activity-date">
            {convertDate(record?.date?.toString())}
          </div>
        </>
      ),
    },
    {
      title: "Ora",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <>
          <div className="table-activity-date">
            {convertTimestamp(record?.date?.toString())}
          </div>
        </>
      ),
    },
    {
      title: "Azioni",
      dataIndex: "",
      render: (id, record) => {
        return (
          <>
            <div className="table-activity-button-details">
              <Space
                size="middle"
                style={{ marginLeft: "15px", marginRight: "0px" }}
              >
                <a onClick={() => {}}>
                  <div className="button-recharge-credits">
                    <Button
                      style={{
                        borderColor: "#D3D4D4",
                        boxShadow: "none",
                        height: "100%",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setDetailsId(record?.id);
                        handleModal(record?.type);
                      }}
                    >
                      <Space style={{ display: "flex", alignItems: "center" }}>
                        <div className="placeholder-credit-recharge">
                          Dettaglio
                        </div>
                      </Space>
                    </Button>
                  </div>
                </a>
              </Space>
            </div>
          </>
        );
      },
    },
  ];

  const convertType = (type: string) => {
    if (type === "SENT") {
      return "Uscita";
    } else if (type === "RECEIVED") {
      return "Entrata";
    } else if (type === "RECHARGED") {
      return "Ricarica";
    } else {
      return null;
    }
  };

  const convertTypeIcon = (type: string) => {
    if (type === "SENT") {
      return <TwentyIcArrowLineDiagonal className={undefined} color="gray" />;
    } else if (type === "RECEIVED") {
      return (
        <TwentyIcCreditsCoinsAdd
          className={undefined}
          color="gray"
          colorC="gray"
        />
      );
    } else if (type === "RECHARGED") {
      return (
        <TwentyIcCreditsCoinsAdd
          className={undefined}
          color="gray"
          colorC="gray"
        />
      );
    } else {
      return null;
    }
  };

  const creditManagement = (type: string) => {
    if (type === "SENT") {
      return "-";
    } else {
      return "+";
    }
  };

  const creditManagemenTable = (type: string) => {
    if (type === "SENT") {
      return "";
    } else {
      return "+";
    }
  };

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const convertDate = (inputDate: string) => {
    const parsedDate = parseISO(inputDate);

    const formattedDate = format(parsedDate, "EEE. dd MMM yyyy", {
      locale: it,
    }).replace(/(\b[a-z](?!\.))/g, (x) => capitalize(x));

    return formattedDate;
  };

  const convertTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    return `${hour}:${minute} `;
  };

  useEffect(() => {
    if (
      localStorage.getItem("credits") != null &&
      localStorage.getItem("stripeId") != null
    ) {
      const stripeId = localStorage.getItem("stripeId") ?? "";
      const stringCredits = localStorage.getItem("credits") ?? 0;
      let credits = 0;
      if (stringCredits) {
        credits = parseInt(stringCredits, 10);
      }
      const walletChargeRequest: WalletChargeRequest = {
        credits: credits,
        stripeId: stripeId,
      };
      store
        .dispatch(chargeWalletAsync({ walletChargeRequest }))
        .then((result: any) => {
          setWallet(result.payload);
        })
        .then(() => {
          store.dispatch(getTransactionsAsync());
        });
      localStorage.removeItem("stripeId");
      localStorage.removeItem("credits");
    }
    store.dispatch(getTransactionsAsync());
  }, []);

  useEffect(() => {
    setWallet(wallets.filter((e) => e.userId === parseInt(userId ?? "0"))[0]);
  }, [wallets]);

  return (
    <div className="credit-content">
      <div className="credit-topbar">
        <div className="credit-topbar-title-iconplace">
          <TwentyIcCreditCoins className="" color="#36B39E" colorC="#36B39E" />
        </div>
        <div className="credit-topbar-title">Gestione crediti</div>
      </div>
      <div className="credit-header">
        <div className="credit-switchbar-tabs">
          <BaseTab
            select={elementSelected === 0}
            text="Stato attuale"
            onClick={() => {
              setElementSelected(0);
            }}
          ></BaseTab>
          <BaseTab
            select={elementSelected === 1}
            text="Attività"
            onClick={() => {
              setElementSelected(1);
            }}
          ></BaseTab>
          {/* <BaseTab
            select={elementSelected === 2}
            text="Richieste"
            onClick={() => {
              setElementSelected(2);
            }}
          ></BaseTab> */}
        </div>
        <div className="button-send-credits">
          <Button
            style={{
              borderColor: "#36B39E",
              backgroundColor: "#36B39E",
              boxShadow: "none",
              height: "100%",
              display: "flex",
              gap: "4px",
              alignItems: "center",
            }}
            onClick={() => {
              setSendCreditModalOpened(!sendCreditModalOpened);
            }}
          >
            <Space style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TwentyIcArrowLineDiagonal
                  color="#FFFFFF"
                  className={undefined}
                ></TwentyIcArrowLineDiagonal>
              </div>
              <div className="smartplan-filtermodal-text-white">
                Invia crediti
              </div>
            </Space>
          </Button>
        </div>
      </div>
      {elementSelected === 0 && (
        <>
          <div className="container-cards">
            <div className="first-card-credits">
              <Card>
                <div className="first-row">
                  <div className="total-credit-topbar">
                    <div className="total-credit-topbar-title-iconplace">
                      <TwentyIcStatLine className="" color="#36B39E" />
                    </div>
                    <div className="total-credit-topbar-title">
                      Crediti totali
                    </div>
                  </div>
                  <div className="button-recharge-credits">
                    <Button
                      style={{
                        borderColor: "#D3D4D4",
                        boxShadow: "none",
                        height: "100%",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setChargeCreditsModal(!chargeCreditsModal);
                      }}
                    >
                      <Space style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TwentyIcCreditsCoinsAdd
                            color="#222729"
                            className={undefined}
                            colorC="#222729"
                          ></TwentyIcCreditsCoinsAdd>
                        </div>
                        <div className="placeholder-credit-recharge">
                          Fai una ricarica
                        </div>
                      </Space>
                    </Button>
                  </div>
                </div>
                <div className="credits-available-text">
                  Crediti disponibili
                </div>
                <div className="credits-view">
                  {wallet && wallet.currentCredits
                    ? addDotToNumber(wallet.currentCredits)
                    : "0"}
                </div>
                <div className="bottom-info">
                  <div className="last-movement-text">
                    Ultimo movimento
                    <div className="row-last-movement">
                      <div className="last-movement-icon">
                        <TwentyIcArrowLineDiagonal
                          color="#7a7d7f"
                          className={undefined}
                        ></TwentyIcArrowLineDiagonal>
                      </div>
                      <div className="last-movement-price">
                        {transactions.length > 0
                          ? creditManagement(transactions[0]?.type) +
                            transactions[0]?.totalCreditSent
                          : "--"}
                      </div>
                    </div>
                  </div>
                  <div className="date-text">
                    Data
                    <div className="row-date">
                      <div className="date-icon">
                        <TwentyIcCalendarSeet
                          color="#7a7d7f"
                          className={undefined}
                        ></TwentyIcCalendarSeet>
                      </div>
                      <div className="date-price">
                        {transactions[0]
                          ? convertDate(transactions[0].date.toString())
                          : "--"}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="second-card-credits">
              <Card>
                <div className="first-row">
                  <div className="movement-topbar">
                    <div className="movement-topbar-title-iconplace">
                      <TwentyIcArrowsLineSortVertical
                        className=""
                        color="#E5B85C"
                      />
                    </div>
                    <div className="movement-topbar-title">Movimenti</div>
                  </div>
                  <div className="button-activity-view">
                    <Button
                      style={{
                        borderColor: "#D3D4D4",
                        boxShadow: "none",
                        height: "100%",
                        display: "flex",
                        gap: "4px",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setElementSelected(1);
                      }}
                    >
                      <Space style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TwentyIcStatBox
                            color="#7a7d7f"
                            className={undefined}
                          ></TwentyIcStatBox>
                        </div>
                        <div className="placeholder-credit-recharge">
                          Vedi attività
                        </div>
                      </Space>
                    </Button>
                  </div>
                </div>
                <div className="transaction-list">
                  {transactions.slice(0, 4).map((transaction, index) => (
                    <div key={index}>
                      <div className="view-transactions">
                        <div className="transaction-icon">
                          {convertTypeIcon(transaction?.type)}
                        </div>
                        <div className="transaction-type">
                          {convertType(transaction?.type)}
                        </div>
                        <div className="transaction-credits">
                          {creditManagement(transaction?.type) +
                            transaction?.totalCreditSent}
                        </div>
                        <div className="transaction-date">
                          {convertDate(transaction?.date.toString())}
                        </div>
                        <div className="transaction-time">
                          {convertTimestamp(transaction?.date.toString())}
                        </div>
                        {/* Non inserire il div aggiuntivo se è l'ultimo elemento */}
                      </div>
                      {index !== transactions.slice(0, 4).length - 1 && (
                        <div className="rectangle-12" />
                      )}
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </div>

          <div className="sending-history">
            <div className="sending-history-text">Storico invii</div>
            <div className="table-sending-a">
              <Table
                columns={columnsHistory}
                dataSource={creditHistory}
                pagination={{
                  pageSize: 12,
                }}
              ></Table>
            </div>
          </div>
        </>
      )}
      {elementSelected === 1 && (
        <div className="sending-history">
          <div className="sending-history-text">Attività</div>
          <div className="table-sending">
            <Table
              columns={columnsActivity}
              dataSource={transactions}
              pagination={{
                pageSize: 12,
              }}
            ></Table>
          </div>
        </div>
      )}
      {openModalDetails && (
        <div>
          <TransactionDetailsModal
            onClose={(v) => {
              setOpenModalDetails(v);
              setTransaction(null);
            }}
            onClick={() => {
              setOpenModalDetails(false);
              setTransaction(null);
            }}
            transactionsDetails={transaction}
          />
        </div>
      )}
      {sendCreditModalOpened && (
        <CreditRechargeEmployeeModal
          onClose={() => setSendCreditModalOpened(!setSendCreditModalOpened)}
        />
      )}
      {openModalRechargeDetails && (
        <div>
          <TransactionRechargeModal
            onClose={(v) => {
              setOpenModalRechargeDetails(v);
            }}
            onClick={() => {
              setOpenModalRechargeDetails(false);
            }}
            transactionsDetails={transaction}
          />
        </div>
      )}
      {chargeCreditsModal && (
        <ChargeCreditsModal
          onClose={() => {
            setChargeCreditsModal(!chargeCreditsModal);
          }}
          title="Ricarica crediti"
        ></ChargeCreditsModal>
      )}
    </div>
  );
};

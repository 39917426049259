/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconDisgust = ({ className, color }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.5"
        cy="10"
        r="8"
        stroke="#86D1C5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="7.5" r="1" fill="#86D1C5" stroke="#86D1C5" />
      <circle cx="13" cy="7.5" r="1" fill="#86D1C5" stroke="#86D1C5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0274 12.4865C10.0249 12.4787 10.0224 12.4708 10.0201 12.4629C9.98568 12.3465 9.92866 12.238 9.85231 12.1436C9.77595 12.0492 9.68176 11.9708 9.5751 11.9129C9.46845 11.8549 9.35142 11.8185 9.23069 11.8057L9.32821 10.8819L9.23069 11.8057C9.10997 11.793 8.98792 11.8042 8.87151 11.8386C8.7551 11.873 8.64661 11.93 8.55224 12.0064L7.93705 11.246L8.55224 12.0064C8.45786 12.0827 8.37945 12.1769 8.32148 12.2836C8.26351 12.3902 8.22711 12.5073 8.21437 12.628C8.20162 12.7487 8.21278 12.8708 8.24721 12.9872C8.40383 13.5168 8.10146 14.0731 7.57184 14.2297C7.04223 14.3863 6.48593 14.084 6.32931 13.5543C6.22041 13.1861 6.1851 12.8 6.22542 12.418C6.26573 12.0361 6.38087 11.6659 6.56427 11.3285C6.74767 10.9911 6.99573 10.6931 7.29429 10.4515C7.59286 10.21 7.93607 10.0296 8.30434 9.92069C8.67261 9.81178 9.05873 9.77648 9.44064 9.81679C9.82256 9.8571 10.1928 9.97225 10.5302 10.1556L10.0527 11.0342L10.5302 10.1556C10.8676 10.339 11.1656 10.5871 11.4072 10.8857C11.6435 11.1778 11.8213 11.5128 11.9309 11.8721C11.9334 11.8799 11.9359 11.8878 11.9382 11.8957C11.9726 12.0121 12.0296 12.1206 12.106 12.215C12.1823 12.3094 12.2765 12.3878 12.3832 12.4457C12.4899 12.5037 12.6069 12.5401 12.7276 12.5528C12.8483 12.5656 12.9704 12.5544 13.0868 12.52C13.2032 12.4856 13.3117 12.4286 13.4061 12.3522C13.5004 12.2759 13.5788 12.1817 13.6368 12.075C13.6948 11.9683 13.7312 11.8513 13.7439 11.7306C13.7567 11.6099 13.7455 11.4878 13.7111 11.3714C13.5545 10.8418 13.8568 10.2855 14.3865 10.1289C14.9161 9.97226 15.4724 10.2746 15.629 10.8042C15.7379 11.1725 15.7732 11.5586 15.7329 11.9405C15.6926 12.3225 15.5774 12.6927 15.394 13.0301C15.2106 13.3675 14.9626 13.6655 14.664 13.9071C14.3654 14.1486 14.0222 14.329 13.654 14.4379C13.2857 14.5468 12.8996 14.5821 12.5177 14.5418C12.1357 14.5015 11.7655 14.3863 11.4281 14.2029C11.0907 14.0195 10.7927 13.7715 10.5511 13.4729C10.3148 13.1808 10.137 12.8458 10.0274 12.4865Z"
        fill="#86D1C5"
      />
    </svg>
  );
};

/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import { useNavigate } from "react-router-dom";

export const TwentyIcArrowLineLeft = ({
  className,
  color = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g opacity="0.7">
        <path
          d="M7 4C7 7.5 1 10 1 10M1 10C1 10 7 12.5 7 16M1 10H19"
          stroke="#222729"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

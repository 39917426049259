import PropTypes from "prop-types";
import { Button } from "../Button/Button";
import "./Modal.css";
import { IconDownload } from "../icons/IconDownload";
import { TwentyIcTik } from "../icons/TwentyIcTik";
import { Checkbox } from "antd";

export const ModalFooter = ({
  primaryAction,
  primaryLabel,
  secondaryAction,
  secondaryLabel,
  secondaryIconLabel,
  loadingPrimaryButton,
  disablePrimaryButton,
  skip,
  disableSecondaryButton,
  hasBack,
  isDanger,
  backAction,
  iconFooter,
  isActionPerforming,
  hasCheckbox,
  onCheck,
}) => {
  const secondaryActionTrigger = () => {
    secondaryAction();
  };

  const primaryActionTrigger = () => {
    primaryAction();
  };

  return (
    <footer
      className={
        hasBack ? "mn-modal__dialog-footer-back" : "mn-modal__dialog-footer"
      }
    >
      {secondaryLabel && secondaryAction && (
        <div className="button-cancel">
          <Button
            type="ghost"
            size="XL"
            text={secondaryLabel}
            onClick={secondaryActionTrigger}
            disabled={disableSecondaryButton}
          />
        </div>
      )}
      {secondaryIconLabel && (
        <Button
          type="line"
          text={secondaryIconLabel}
          onClick={secondaryActionTrigger}
        >
          <IconDownload size={14} color="--global-colors-ink-ink" />
        </Button>
      )}
      {hasBack && backAction && (
        <Button type="secondary" text="annulla" onClick={backAction} />
      )}
      {hasCheckbox && onCheck && (
        <>
          <div className="checkbox-privacy-policy">
            <Checkbox onChange={() => onCheck()}>
              Ho letto e accetto i Termini e Condizioni (obbligatorio)
            </Checkbox>
          </div>
        </>
      )}
      {primaryLabel && primaryAction && (
        <Button
          icon={isActionPerforming ? "" : <TwentyIcTik />}
          type="primary"
          skip={skip}
          text={isActionPerforming ? "" : primaryLabel}
          disabled={disablePrimaryButton}
          isLoading={loadingPrimaryButton}
          onClick={primaryActionTrigger}
          isActionPerforming={isActionPerforming}
          size="XL"
          textColor={"white"}
        >
          {iconFooter}
        </Button>
      )}
    </footer>
  );
};

ModalFooter.propTypes = {
  primaryAction: PropTypes.func,
  primaryLabel: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryLabel: PropTypes.string,
  secondaryIconLabel: PropTypes.string,
  loadingPrimaryButton: PropTypes.bool,
  disablePrimaryButton: PropTypes.bool,
  disableSecondaryButton: PropTypes.bool,
  hasBack: PropTypes.bool,
  isDanger: PropTypes.bool,
  backAction: PropTypes.func,
  iconFooter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
